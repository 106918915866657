import { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface ActiveLinkContextType {
  activeLink: string;
  setActiveLink: (link: string) => void;
}

const ActiveLinkContext = createContext<ActiveLinkContextType | undefined>(undefined);

const ACTIVE_LINK_TTL = 3600 * 1000;
const CACHE_VERSION = "1.2";

export const ActiveLinkProvider = ({ children }: { children: ReactNode }) => {
  const [activeLink, setActiveLinkState] = useState('/');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedCacheVersion = localStorage.getItem('cacheVersion');
      if (storedCacheVersion !== CACHE_VERSION) {
        localStorage.clear();
        localStorage.setItem('cacheVersion', CACHE_VERSION);
      }
      const storedActiveLink = getItemWithExpiry('activeLink');
      const currentPath = window.location.pathname;

      if (!storedActiveLink || storedActiveLink !== currentPath) {
        setActiveLinkState(currentPath);
        setItemWithExpiry('activeLink', currentPath, ACTIVE_LINK_TTL);
      } else {
        setActiveLinkState(storedActiveLink);
      }
    }
  }, []);

  const setActiveLink = (link: string) => {
    if (link !== activeLink) {
      setItemWithExpiry('activeLink', link, ACTIVE_LINK_TTL);
      setActiveLinkState(link);
    }
  };

  return (
    <ActiveLinkContext.Provider value={{ activeLink, setActiveLink }}>
      {children}
    </ActiveLinkContext.Provider>
  );
};

export const useActiveLink = () => {
  const context = useContext(ActiveLinkContext);
  if (!context) {
    throw new Error('useActiveLink must be used within an ActiveLinkProvider');
  }
  return context;
};
const setItemWithExpiry = (key: string, value: string, ttl: number) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiry = (key: string): string | null => {
  try {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch (error) {
    localStorage.removeItem(key);
    return null;
  }
};

