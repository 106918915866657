import { useActiveLink } from "../context/activelink";
import {
  HomeIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  CalculatorIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useCallback } from "react";

export const BottomNav = () => {
  const { activeLink, setActiveLink } = useActiveLink();

  const handleClick = useCallback(
    (href: string) => {
      if (href !== activeLink) {
        setActiveLink(href);
      }
    },
    [activeLink, setActiveLink]
  );

  const getClasses = (link: string, type: "link" | "icon" | "text") => {
    const baseClasses = {
      link: "inline-flex flex-col items-center justify-center px-2 group",
      icon: "w-7 h-7",
      text: "text-xs text-center",
    };
    const activeClasses = {
      link: "bg-gray-100 dark:bg-gray-800 text-blue-600 dark:text-gray-100",
      icon: "text-blue-600",
      text: "text-blue-600 dark:text-gray-100",
    };
    const inactiveClasses = {
      link: "text-gray-600 dark:text-gray-200",
      icon: "text-gray-600 dark:text-gray-200",
      text: "text-gray-600 dark:text-gray-200",
    };
    return `${baseClasses[type]} ${activeLink === link ? activeClasses[type] : inactiveClasses[type]
      }`;
  };

  return (
    <div className="block fixed bottom-0 border-t dark:border-gray-800 lg:hidden left-0 z-50 w-full h-16 bg-white dark:bg-gray-900 shadow-shadow-custom">
      <div className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium">
        <Link
          href="/" onClick={() => handleClick("/")} className={getClasses("/", "link")}>
          <HomeIcon className={getClasses("/", "icon")} />
          <span className={getClasses("/", "text")}>Beranda</span>
        </Link>
        <Link href="/cek-pajak-kendaraan-bermotor-online" replace onClick={() => handleClick("/cek-pajak-kendaraan-bermotor-online")}
          className={getClasses("/cek-pajak-kendaraan-bermotor-online", "link")}>
          <DocumentMagnifyingGlassIcon className={getClasses("/cek-pajak-kendaraan-bermotor-online", "icon")} />
          <span className={getClasses("/cek-pajak-kendaraan-bermotor-online", "text")}>
            Cek Pajak
          </span>
        </Link>
        <Link href="/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan" onClick={() => handleClick("/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan")}
          className={getClasses("/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan", "link")}>
          <DocumentTextIcon className={getClasses("/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan", "icon")} />
          <span className={getClasses("/syarat-proses-dan-cara-mutasi-balik-nama-dan-bayar-pajak-kendaraan", "text")}>
            Petunjuk
          </span>
        </Link>
        <Link href="/tarif-pnbp-kendaraan-bermotor" onClick={() => handleClick("/tarif-pnbp-kendaraan-bermotor")}
          className={getClasses("/tarif-pnbp-kendaraan-bermotor", "link")}>
          <CalculatorIcon className={getClasses("/tarif-pnbp-kendaraan-bermotor", "icon")} />
          <span className={getClasses("/tarif-pnbp-kendaraan-bermotor", "text")}>
            Tarif
          </span>
        </Link>
        <Link href="/cek-nilai-jual-kendaraan-bermotor-online" onClick={() => handleClick("/cek-nilai-jual-kendaraan-bermotor-online")}
          className={getClasses("/cek-nilai-jual-kendaraan-bermotor-online", "link")}>
          <BanknotesIcon className={getClasses("/cek-nilai-jual-kendaraan-bermotor-online", "icon")} />
          <span className={getClasses("/cek-nilai-jual-kendaraan-bermotor-online", "text")}>
            NJKB
          </span>
        </Link>
      </div>
    </div>
  );
};
